import React from "react"
import { Col, Row, Container } from "react-awesome-styled-grid"

import { Title, Desc, CatHeaderContainer, CatHeaderBg, InfoContainer } from "./CategoryHeader.style"

const CategoryHeader = ({ title, desc }) => {
  const modulClass = "m-category-header"
  return (
    <CatHeaderContainer className={modulClass}>
      <CatHeaderBg />
      <Container>
        <Row>
          <Col xs={4}>
            <InfoContainer>
              <Title>{title}</Title>
              <Desc>{desc}</Desc>
            </InfoContainer>
          </Col>
        </Row>
      </Container>
    </CatHeaderContainer>
  )
}

export default CategoryHeader
