import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import bgMapImage from "../../../../static/assets/images/texturas/textura-tile-mapa.png"
import bgNoiseImage from "../../../../static/assets/images/texturas/textura-tile-noise.png"

export const CatHeaderContainer = styled.div`
  padding-bottom: 200px;
  position: relative;
  /* z-index: 10; */

  .m-category-header__bg-top {
    width: 768px;
    height: 279px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 371px;
  }
  .m-category-header__black-block {
    position: absolute;
    display: block;
    width: 100%;
    background-color: ${props => props.theme.vars.mirage};
    height: 371px;
  }

  @media (min-width: 650px) {
    padding-bottom: 300px;
  }
  @media ${device.sm} {
    padding-bottom: 370px;

    .m-category-header__bg-top {
      width: 1024px;
      height: 349px;
      top: 238px;
    }
    .m-category-header__black-block {
      height: 238px;
    }
  }
  @media ${device.md} {
    padding-bottom: 30vw;

    .m-category-header__bg-top {
      width: 1366px;
      height: 420px;
      top: 268px;
    }
    .m-category-header__black-block {
      height: 268px;
    }
  }
  @media ${device.lg} {
    padding-bottom: 28vw;

    .m-category-header__bg-top {
      width: 1920px;
      height: 500px;
      top: 227px;
    }
    .m-category-header__black-block {
      height: 227px;
    }
  }
  @media ${device.xl} {
    padding-bottom: 25vw;

    .m-category-header__bg-top {
      height: 500px;
      top: 453px;
    }
    .m-category-header__black-block {
      height: 453px;
    }
  }
`

export const CatHeaderBg = styled.div`
  background-color: ${props => props.theme.vars.steelGray};
  background-image: url(${bgMapImage}), url(${bgNoiseImage});
  height: 125vh;
  position: absolute;
  top: 0;
  width: 100%;
`

export const InfoContainer = styled.div`
  text-align: center;
  padding: 92px 14px 92px;
  z-index: 3;

  @media ${device.sm} {
    padding: 114px 69px 0;
  }
  @media ${device.md} {
    padding: 168px 134px 0;
  }
  @media ${device.lg} {
    padding: 161px 229px 0;
  }
  @media ${device.xl} {
    padding: 160px 229px 0;
  }
`

export const Title = styled.h1`
  font-family: ${props => props.theme.vars.avenirNext};
  font-size: 28px;
  font-weight: ${props => props.theme.vars.fontBold};
  color: ${props => props.theme.vars.midGray};
  text-transform: uppercase;
  margin-bottom: 9px;

  @media ${device.sm} {
    font-size: 36px;
    margin-bottom: 13px;
  }
  @media ${device.md} {
    font-size: 48px;
    margin-bottom: 11px;
  }
  @media ${device.lg} {
    margin-bottom: 9px;
  }
  @media ${device.xl} {
    font-size: 72px;
    margin-bottom: 25px;
  }
`

export const Desc = styled.p`
  font-size: 16px;
  font-weight: ${props => props.theme.vars.fontRegular};
  color: ${props => props.theme.vars.white};
  line-height: 24px;

  @media ${device.sm} {
    line-height: 20px;
  }
  @media ${device.md} {
    line-height: 25px;
  }
  @media ${device.lg} {
    line-height: 22px;
  }
  @media ${device.xl} {
    padding: 0 173px;
    line-height: 24px;
  }
`
