export const categories = {
  destacadas: {
    title: 'DESTACADAS',
    desc:
      'No importa cuál sea tu próximo destino ni la ruta que tomes para llegar a él porque ten por seguro que te llevaremos hasta allá. Sea montaña, selva, desierto, playa o ciudad tu Jeep siempre te acompañará, tal como en estas historias de los que así como tú comparten el ADN aventurero.',
  },
  offroad: {
    title: 'OFF-ROAD',
    desc:
      'No importa cuál sea tu próximo destino ni la ruta que tomes para llegar a él porque ten por seguro que te llevaremos hasta allá. Sea montaña, selva, desierto, playa o ciudad tu Jeep siempre te acompañará, tal como en estas historias de los que así como tú comparten el ADN aventurero.',
  },
  familia: {
    title: 'FAMILIA',
    desc:
      'No importa cuál sea tu próximo destino ni la ruta que tomes para llegar a él porque ten por seguro que te llevaremos hasta allá. Sea montaña, selva, desierto, playa o ciudad tu Jeep siempre te acompañará, tal como en estas historias de los que así como tú comparten el ADN aventurero.',
  },
  aventura: {
    title: 'AVENTURA',
    desc:
      'No importa cuál sea tu próximo destino ni la ruta que tomes para llegar a él porque ten por seguro que te llevaremos hasta allá. Sea montaña, selva, desierto, playa o ciudad tu Jeep siempre te acompañará, tal como en estas historias de los que así como tú comparten el ADN aventurero.',
  },
};
